import React from 'react'
import { graphql, Link } from 'gatsby'
import LayoutGeneral from '../components/Layouts'
import Hero from '../components/Hero'
import BreadCrumbPath from '../components/BreadCrumbPath'
import SectionHierarchy from '../components/SectionHierarchy'
import Article from '../components/Article'
import PrevNextDocumentation from '../components/PrevNextDocumentation'
import SEO from '../components/SEO'
import Content from '../components/Content'
import {Container, Row, Col, Breadcrumb} from 'react-bootstrap'
import styled from 'styled-components'
import { colors, media } from '../tokens'

const SectionCol = styled(Col)`
  border-radius: 5px;
  word-wrap: break-word;
  background-color: ${colors.backgroundArticle};
  top: 15px;
  padding: 30px 5px 30px 15px;
  margin-bottom: 30px; 
  box-shadow: 0 0 0 0, 0 6px 12px rgba(0, 0, 0, 0.1);
`

const ContentCol = styled(Col)`
  padding: 0px;
  top: 15px;
`

const BodyRow = styled(Row)`
  margin-bottom: 30px;
`

const ContentDiv = styled.div`
  border-radius: 5px;
  word-wrap: break-word;
  background-color: ${colors.backgroundArticle};
  padding: 30px 5px 30px 15px;
  box-shadow: 0 0 0 0, 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-left: 30px;

  @media ${media.maxLarge} {
    margin-left: 0px;
  }
`

class DocumentationTemplate extends React.Component {
  render() {
    const page = this.props.data.page
    const { previous, next } = this.props.pageContext

    const DocumentationName = page.fields.slug.split('/')[2];
    var localSlug = page.fields.slug.split('/Documentation/'+DocumentationName+'/')[1];
    if(localSlug == undefined || localSlug == "undefined"){
      localSlug=""
    }

    //Add space before Upper case
    const documentationLabel = DocumentationName.replace(/([A-Z])/g, ' $1').trim()
    const title = page.frontmatter.title;//documentationLabel+" : "+

    const linkPrefix = "Documentation/";
    const localPath = DocumentationName+"/"+localSlug

    return (
      <LayoutGeneral location={this.props.location}>
        <SEO
          title={title}
          description={page.excerpt}
          cover={page.frontmatter.cover && page.frontmatter.cover.publicURL}
          imageShare={
            page.frontmatter.imageShare && page.frontmatter.imageShare.publicURL
          }
          lang={page.frontmatter.language}
          path={page.fields.slug}
        />

        <Hero
          heroImg={page.frontmatter.cover && page.frontmatter.cover.publicURL}
          title={title}
        />

        <Container> 
          <BreadCrumbPath linkPrefix={linkPrefix} localPath={localPath}></BreadCrumbPath>

          <BodyRow>
            <SectionCol lg={3}>
              <SectionHierarchy linkPrefix={linkPrefix} slugPrefix={"/"+linkPrefix+DocumentationName+"/"} localPath={localPath.replace(DocumentationName+"/","")}></SectionHierarchy>
            </SectionCol>
            <ContentCol lg={9}>
              <ContentDiv>
                <article>
                  <Content content={page.body} />
                </article>
              </ContentDiv>
            </ContentCol>
          </BodyRow>

          <PrevNextDocumentation previous={previous} next={next} />

        </Container>
      </LayoutGeneral>
    )
  }
}

export default DocumentationTemplate

export const pageQuery = graphql`
  query ($slug: String!) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      excerpt
      body
      fields{
        slug
      }
      frontmatter {
        title
        date
        slug
        language
        tags
        cover {
          publicURL
        }
        coverPublic
        imageShare {
          publicURL
        }
        translations {
          language
          link
        }
      }
    }
  }
`