import React from 'react'
import { graphql, Link } from 'gatsby'
import {Breadcrumb} from 'react-bootstrap'
import styled from 'styled-components'
import { colors, media } from '../tokens'

function PathToName(path){
    return path.replace(/([A-Z])/g, ' $1').trim().replace("-"," ");
}

function ConcatItems(items, count, separator){
    
    var path = items[0]
    for(var i = 1; i<= count; i++){
        path+= separator+items[i]
    }
    return path
}

const BreadCrumbLinkItems =  (props) => {
    const {linkPrefix, items} = props
    return items.map((item, i) => (
    <Breadcrumb.Item><Link to={linkPrefix+ConcatItems(items,i,"/")}>{PathToName(item)}</Link></Breadcrumb.Item>
    ))
}

const BreadCrumbPath = (props) => {
    const {linkPrefix, localPath} = props

    const paths = localPath.trim().split('/')
    
    var filteredPaths = paths.filter(function (el) {
        return el;
      });
 
    const activeItem = PathToName(filteredPaths.pop())

    if(filteredPaths.length == 0){
        return (
            <Breadcrumb>
                <Breadcrumb.Item active>{activeItem}</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    return (
        <Breadcrumb>
            <BreadCrumbLinkItems linkPrefix={linkPrefix} items={filteredPaths}></BreadCrumbLinkItems>
            <Breadcrumb.Item active>{activeItem}</Breadcrumb.Item>
        </Breadcrumb>
    )
    
}

export default BreadCrumbPath