import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { colors, media } from '../tokens'
import useAllMdxNodes from '../hooks/use-allMdx-config'
import {Row} from 'react-bootstrap'

const Wrapper = styled.div`

    a:hover{
        color: ${colors.dypPurpleLight}
    } 

    .collapsing-children{
        padding-left 10px;
    }

    .collapse-button{
        flex-grow: 1;
        text-align: right;
        margin-right: 10px; 

        background: none!important;
        border: none;
        padding: 0!important;
        color: ${colors.dypBlue};
        cursor: pointer;
        outline-color: transparent;
        font: bold 20px arial, sans-serif;
    }

    .collapse-parent{
        position: relative;
        margin: 0;
    }
`

class CollapsingSections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.currentPath.startsWith(props.section.localSlug),
        };
        this.toggleClass = this.toggleClass.bind(this);
    }

    toggleClass() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    };

    render() {

        const {section, currentPath} = this.props;

        if(section.children.length == 0){
            return <div><Link to={section.slug}>{section.name}</Link></div>
        }
        return(
            <div>
                <Row className="collapse-parent">
                    <Link to={section.slug}>{section.name}</Link>
                    <button onClick={this.toggleClass} className="collapse-button">{this.state.active ? '-': '+'}</button>
                </Row>  
                <div className={"collapsing-children "+(this.state.active ? "": 'collapse')}>
                    {section.children.map((sectionChild,i)=>{
                        return <CollapsingSections section={sectionChild} currentPath={currentPath}></CollapsingSections>
                    })}
                </div>
            </div>
        )
  }
}

function SearchForParent(sectionList,newSectionPath, maxLength){
    var parentSection = null;
    sectionList.forEach(section => {
        var sectionPath = section.localSlug.split("/")
        for (let i = 0; i < sectionPath.length; i++) {
            if(newSectionPath[i] != sectionPath[i]){
                break;
            }
            if(maxLength <= i){
                maxLength +=1;
                parentSection = section;
            }
        }
    });

    if(parentSection == null){
        return null;
    }else{
        if(parentSection.children.length == 0){
            return parentSection;
        }else{
            var parentInChild =SearchForParent(parentSection.children,newSectionPath,maxLength);
            if(parentInChild == null){
                return parentSection;
            }
            return parentInChild;
        }
    } 
}

const SectionHierarchy = (props) => {
    const {slugPrefix, localPath} = props

    const nodes = useAllMdxNodes();

    var organizedSections = [];

    nodes.map((node, i) => {
        const fields = node.fields
        if(fields == null){return;}
        const slug = fields.slug;
        //console.log(slug);
        if (slug.startsWith(slugPrefix)) {

            const localSlug= slug.split(slugPrefix)[1];
            const name = node.frontmatter.title;

            var children = [];
            var newSection = { slug, localSlug, name, children }

            const parentSection = SearchForParent(organizedSections,localSlug.split("/"),0)

            if(parentSection == null){
                organizedSections.push(newSection)
            }else{
                parentSection.children.push(newSection)
            }
        }
      });

    //console.log(organizedSections)

    var sections = organizedSections.map((section,i) => {
        return <CollapsingSections section={section} currentPath={localPath}></CollapsingSections>
    })

    return (
        <Wrapper>
            {sections}
        </Wrapper>
        )
    
}

export default SectionHierarchy