import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'
import { ReadingTime, Bull } from './Commons'
import { colors, media } from '../tokens'
import { Container, Row, Col, Form, FormControl, Button } from 'react-bootstrap'
import '../utils/FontAwesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.div({
  maxWidth: '920px',
  margin: '0 auto',

  row : {
    margin: '0',
  }
})

const PrevBlock = props =>{
  const { node } = props;
  if(node == null){return <div></div>}

  const { timeToRead } = node
  const { title } = node.frontmatter
  const slug = node.fields.slug

  return (
    <Link to={slug}>
      <Row>
        <Col xs={2}>
          <h1><FontAwesomeIcon icon={['fas', 'chevron-left']}></FontAwesomeIcon></h1>
        </Col>
        <Col xs={10} className="text-right">
            <h2>{title}</h2>
            <ReadingTime min={timeToRead} />
        </Col>
      </Row>
    </Link>
    )
}

const NextBlock = props =>{
  const { node } = props;
  if(node == null){return <div></div>}

  const { timeToRead } = node
  const { title } = node.frontmatter
  const slug = node.fields.slug

  return (
    <Link to={slug}>
      <Row>
        <Col xs={10}>
          <h2>{title}</h2>
          <ReadingTime min={timeToRead} />
        </Col>
        <Col xs={2}>
          <h1><FontAwesomeIcon icon={['fas', 'chevron-right']}></FontAwesomeIcon></h1>
        </Col>
      </Row>
    </Link>
    )
}

const PrevNextDocumentation = props => {
  const { previous, next } = props
  const articles = [previous, next].filter(i => i).map(item => ({ node: item }))
  const { siteCover, defaultLang } = useSiteMetadata()
  const { fluid } = useSiteImages(siteCover)

  return (
    <Wrapper>
      <Row>
        <Col xs={5}>
          <PrevBlock node={previous}></PrevBlock>
        </Col>
        <Col xs={2}>
        </Col>
        <Col xs={5}>
          <NextBlock node={next}></NextBlock>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default PrevNextDocumentation
